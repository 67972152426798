<template>
  <div class="tab-pane fade active show" id="nav-exact" role="tabpanel" aria-labelledby="nav-exact-tab">

    <date-range-control v-model="selectedDateRange" v-if="device==='desktop'"/>

    <div class="compositionarea_footer d-flex align-items-center justify-content-between" v-if="device==='desktop'">
      <div class="btn-group-area d-flex justify-content-start">
        <button class="holiday_btn">{{$t("sabre.search-panel.holiday")}}</button>
        <button class="specailday_btn">{{$t("sabre.search-panel.special-day")}}</button>
        <button class="specailday_btn1">{{$t("sabre.search-panel.special-day")}}</button>
      </div>
      <div class="btn-group-area d-flex justify-content-end">
        <button class="cleanup_btn" @click="clearUp">{{$t("sabre.buttons.clean")}}</button>
        <button class="save_btn" @click="chooseDate">{{$t("sabre.buttons.save")}}</button>
      </div>
    </div>

    <date-range-control v-model="selectedDateRange" v-if="device==='mobile'"/>

  </div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'sabre-exact-date',
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  components: {
    dateRangeControl: () => import('./dateRangeControl'),
    // customDatepicker: () => import('./appDaterangePicker'),
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      storedDateRange: 'GET_SABRE_EXACT_DATE_RANGE',
    }),
  },
  watch: {
    selectedDateRange() {
      // this.$emit('update', { date: this.selectedDateRange, type: this.type });
      this.$store.commit('SET_SABRE_EXACT_DATE_RANGE', this.selectedDateRange);
    },
  },
  data() {
    return {
      selectedDateRange: [],
    };
  },
  mounted() {
    this.selectedDateRange = this.storedDateRange;
  },
  methods: {
    chooseDate() {
      this.$emit('close');
    },
    clearUp() {
      const dateControl = this.$children.find((child) => child.$options._componentTag === 'date-range-control');
      dateControl.clear();
      // this.selectedDateRange = [];
    },
  },
};

</script>
